.button {
  border: unset;
  outline: unset;
  width: 100%;
  height: 40px;
  background-color: #5c57ff;
  font-size: 15px;
  letter-spacing: 1.5px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &:disabled {
    background-color: #cfdeff;
    cursor: not-allowed;
  }

  &:active {
    background-color: #3f3cca;
    color: #5c57ff;
  }

  &:hover {
    box-shadow: 0 0 10px 0 rgba(18, 12, 213, 0.5);
  }
}
