.survey {
  width: 100%;
  min-height: calc(100vh - 98px);
  background-color: #5c57ff;

  @media (min-width: 1280px) {
    padding-top: 50px;
    display: grid;
    grid-template-columns: 1fr 570px;
    align-items: start;
    justify-items: start;
  }

  @media (min-width: 1428px) {
    grid-template-columns: 1fr calc(520px + calc(calc(100vw - 1292px) / 2));
  }
}

.survey__text-wrapper {
  width: calc(100% - 50px);
  margin: auto;
  padding-top: 25px;
  display: grid;
  grid-row-gap: 13px;

  @media (min-width: 1024px) {
    padding-top: 28px;
    grid-row-gap: 27px;
  }

  @media (min-width: 1280px) {
    grid-column: 1;
    grid-row: 1;
    margin: unset;
    padding-top: 37px;
    padding-left: 50px;
    grid-row-gap: 17px;
  }

  @media (min-width: 1428px) {
    padding-left: calc(36px + calc(calc(100vw - 1292px) / 2));
  }
}

.survey__title {
  width: fit-content;
  margin: auto;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.34;
  letter-spacing: -0.75px;
  color: #fff;

  @media (min-width: 1024px) {
    font-size: 42px;
    line-height: 1.19;
    letter-spacing: -1.05px;
  }

  @media (min-width: 1280px) {
    width: 445px;
    margin: unset;
    line-height: 1.2;
  }
}

.survey__subtitle {
  width: fit-content;
  max-width: 568px;
  margin: auto;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;

  @media (min-width: 568px) {
    text-align: center;
  }

  @media (min-width: 1024px) {
    font-size: 22px;
    line-height: 1.35;
  }

  @media (min-width: 1280px) {
    text-align: left;
    margin: unset;
  }
}

.survey__image {
  display: none;
  margin-top: 38px;

  &--375 {
    display: block;
  }

  @media (min-width: 768px) {
    margin-top: -35px;

    &--375 {
      display: none;
    }

    &--768 {
      display: block;
    }
  }

  @media (min-width: 1280px) {
    margin-top: 18px;
    grid-column: 1;
    grid-row: 2;

    &--768 {
      display: none;
    }

    &--1280 {
      display: block;
    }
  }

  @media (min-width: 1920px) {
    margin-top: -98px;

    &--1280 {
      display: none;
    }

    &--1920 {
      display: block;
    }
  }
}
