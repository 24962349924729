.dropdown {
  width: 100%;
  display: grid;
  grid-row-gap: 7px;
}

.dropdown__title {
  font-size: 13px;
  padding-left: 6px;

  &--required {
    &::after {
      content: '*';
      color: #fd0100;
    }
  }
}

.indicator {
  margin-top: 2px;
  transition: all 200ms ease-in-out;

  &--open {
    transform: rotate(180deg);
  }
}

.dropdown__mock {
  width: 100%;
  height: 43px;
  border-radius: 6px;
  border: solid 1px #5c57fa;
  background-color: #f7f7f7;
}
