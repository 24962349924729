@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  color: #1c1c1c;
  line-height: 1;
}
