.form {
  width: calc(100% - 30px);
  max-width: 520px;
  margin: 23px auto 0;
  padding: 25px;
  border-radius: 10px;
  background-color: #fff;

  &--user-stage {
    padding: 50px 25px;
    border-radius: 20px;
    margin-bottom: -15px;
  }

  @media (min-width: 768px) {
    border-radius: 20px;
    padding: 50px;

    &--user-stage {
      margin-bottom: 35px;
    }
  }

  @media (min-width: 1024px) {
    margin-top: 25px;
  }

  @media (min-width: 1280px) {
    grid-column: 2;
    grid-row: 1 / 3;
    margin: unset;
  }

  @media (min-width: 1920px) {
    &--user-stage {
      margin-top: -2px;
    }
  }
}

.form__title {
  width: 100%;
  max-width: 320px;
  margin: auto;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: -0.75px;
  text-align: center;
  text-transform: capitalize;
}

.form__indicators-wrapper {
  width: 198px;
  height: 7px;
  margin: 24px auto 27px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 14px;
}

.form__indicator {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: solid 1px #5c57ff;
  background-color: #fff;

  &--active {
    background-color: #5c57ff;
  }

  &--clickable {
    cursor: pointer;
  }
}

.form__inputs-wrapper {
  width: 100%;
  max-width: 320px;
  margin: auto;
  display: grid;
  grid-row-gap: 12px;
}

.form__year-province-dropdown-wrapper {
  display: grid;
  grid-template-columns: 70px 1fr;
  grid-column-gap: 15px;

  @media (min-width: 768px) {
    grid-template-columns: 80px 1fr;
    grid-column-gap: 25px;
  }
}

.form__remaining-text {
  text-align: right;
  font-size: 10px;
}

.form__controls-wrapper {
  max-width: 320px;
  margin: 25px auto 0;
  display: grid;
  grid-template-columns: 1fr 144px;
  grid-column-gap: 20px;
  align-items: center;

  &--user-stage {
    width: 100%;
    max-width: 320px;
    grid-template-columns: 12px 1fr;
    grid-column-gap: 15px;
  }

  @media (min-width: 768px) {
    &--user-stage {
      grid-column-gap: 20px;
    }
  }
}

.form__icon-prev-stage {
  cursor: pointer;
  align-self: center;
}

.form__link {
  font-size: 14px;
  font-weight: bold;
  color: #3f3cca;
  text-decoration: underline;
  cursor: pointer;
}

.form__button {
  &--user-stage {
    letter-spacing: 0.75px;
  }

  @media (min-width: 768px) {
    &--user-stage {
      letter-spacing: 1.5px;
    }
  }
}
