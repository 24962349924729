.result {
  width: 100%;
  min-height: calc(100vh - 100px);
  background-color: #5c57ff;
  padding: 25px;
  display: grid;
  align-items: flex-start;

  @media (min-width: 375px) {
    min-height: calc(90vh);
  }
  @media (min-width: 1024px) {
    padding-bottom: 49px;
    min-height: calc(100vh - 98px);
  }
  @media (min-width: 1920px) {
    display: block;
    padding-bottom: 25px;
  }
}

.result__wrapper {
  width: 100%;
  display: grid;
  grid-row-gap: 25px;

  @media (min-width: 1024px) {
    grid-row-gap: 20px;
  }

  @media (min-width: 1280px) {
    width: fit-content;
    margin: auto;
    margin-top: -5px;
    grid-template-columns: repeat(2, max-content);
    grid-row-gap: unset;
    grid-column-gap: 20px;
    align-items: start;
  }
  @media (min-width: 1920px) {
    margin-top: 91px;
  }
}
.result__wrapper_modified {
  width: 100%;
  display: grid;
  grid-row-gap: 25px;

  @media (min-width: 1024px) {
    grid-row-gap: 20px;
  }

  @media (min-width: 1280px) {
    width: fit-content;
    margin: auto;
    grid-template-columns: repeat(2, max-content);
    grid-row-gap: unset;
    grid-column-gap: 20px;
    align-items: start;
  }
  @media (min-width: 1920px) {
    margin-top: 91px;
  }
}

.result__info-wrapper,
.result__guide-wrapper {
  width: 100%;
  max-width: 600px;
  margin: auto;
  border-radius: 20px;
  overflow: hidden;
  background-color: #fff;

  @media (min-width: 768px) {
    width: 600px;
  }

  @media (min-width: 1280px) {
    width: 540px;
    height: 660px;
  }

  @media (min-width: 1920px) {
    width: 600px;
    height: 748px;
  }
}

.result__photo {
  width: 100%;
  height: 57.87vw;
  max-height: 400px;
  object-fit: cover;
  display: block;

  @media (min-width: 1280px) {
    max-height: 360px;
  }

  @media (min-width: 1920px) {
    max-height: 400px;
  }
}

.result__info {
  width: 100%;
  padding: 25px 0 26px;

  @media (min-width: 1024px) {
    padding-bottom: 39px;
  }

  @media (min-width: 1280px) {
    padding-bottom: 49px;
  }
}

.result__info_modified {
  width: 100%;
  padding: 25px 0 26px;

  @media (min-width: 1024px) {
    padding-bottom: 39px;
  }

  @media (min-width: 1280px) {
    padding-bottom: 49px;
  }
}

.result__title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: -0.75px;
  text-transform: capitalize;
  text-align: center;

  @media (min-width: 1280px) {
    font-size: 21px;
    letter-spacing: -1.05px;
    &--next-steps {
      margin-left: 124px;
      text-align: left;
    }
  }
  @media (min-width: 1920px) {
    font-size: 25px;
  }
}

.result__title_modified {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: -0.75px;
  text-transform: capitalize;
  text-align: center;

  @media (min-width: 1280px) {
    margin-top: 6px;
    &--next-steps {
      margin-left: 124px;
      text-align: left;
    }
  }
  @media (min-width: 1280px) {
    margin-top: 0px;
  }
  @media (min-width: 1920px) {
    margin-top: 2px;
  }
}

.result__make {
  width: calc(100% - 50px);
  margin: auto;
  margin-top: 13px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.45px;
  text-align: center;

  @media (min-width: 1024px) {
    margin-top: 13.5px;
  }

  @media (min-width: 1280px) {
    margin-top: 8px;
    font-size: 16px;
    letter-spacing: -0.33px;
  }

  @media (min-width: 1920px) {
    font-size: 18px;
    margin-top: 13px;
  }
}

.result__make_modified {
  width: calc(100% - 50px);
  margin: auto;
  margin-top: 13px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.45px;
  text-align: center;

  @media (min-width: 1024px) {
    margin-top: 9.5px;
  }

  @media (min-width: 1280px) {
    margin-top: 7px;
    font-size: 16px;
    letter-spacing: -0.3px;
  }

  @media (min-width: 1920px) {
    font-size: 18px;
    margin-top: 12px;
  }
}

.result__trim {
  width: calc(100% - 50px);
  margin: auto;
  margin-top: 12px;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.35px;
  text-align: center;

  @media (min-width: 1024px) {
    margin-top: 9px;
  }
  @media (min-width: 1280px) {
    margin-top: 2px;
  }
  @media (min-width: 1920px) {
    margin-top: 6px;
  }
}
.result__trim_modified {
  width: calc(100% - 50px);
  margin: auto;
  margin-top: 12px;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.35px;
  text-align: center;

  @media (min-width: 1024px) {
    margin-top: 2px;
  }
  @media (min-width: 1920px) {
    margin-top: 12px;
  }
}

.result__text-info {
  width: calc(100% - 82px);
  margin: auto;
  margin-top: 21px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #404040;
  @media (min-width: 1024px) {
    width: 384px;
    margin-top: 19px;
    margin-bottom: 21px;
  }
  @media (min-width: 1280px) {
    width: 384px;
    margin-bottom: 40px;
    margin-top: 12px;
  }
  @media (min-width: 1920px) {
    width: 384px;
    margin-bottom: 60px;
    margin-top: 21px;
  }
}

.result__trade-in-price-title {
  width: 100%;
  margin-top: 15px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.35px;
  color: #36dec5;

  @media (min-width: 375px) {
    letter-spacing: 0.5px;
  }

  @media (min-width: 1024px) {
    letter-spacing: 0;
    margin-top: 17px;
  }

  @media (min-width: 1280px) {
    margin-top: 5px;
  }

  @media (min-width: 1920px) {
    margin-top: 15px;
  }
}

.result__trade-in-price {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.6px;
  text-align: center;
  color: #36dec5;
  margin-top: 6px;

  @media (min-width: 375px) {
    letter-spacing: 0;
  }

  @media (min-width: 768px) {
    font-size: 25px;
    margin-top: 6px;
  }
  @media (min-width: 1024px) {
    margin-top: 4px;
  }
}

.result__direct-sale-price-title {
  width: 100%;
  margin-top: 16px;
  text-align: center;
  font-size: 12px;
  line-height: 1.75;
  letter-spacing: -0.3px;

  @media (min-width: 1024px) {
    letter-spacing: 0;
    margin-top: 9px;
  }

  @media (min-width: 1280px) {
    margin-top: 8px;
  }
  @media (min-width: 1920px) {
    margin-top: 13px;
  }
}

.result__direct-sale-price {
  width: 100%;
  text-align: center;
  font-size: 21px;
  font-weight: normal;
  letter-spacing: -0.53px;
  text-align: center;
  margin-top: 2px;

  @media (min-width: 375px) {
    letter-spacing: 0;
  }

  @media (min-width: 768px) {
    font-size: 21px;
  }
}

.result__description {
  width: calc(100% - 25px);
  max-width: 372px;
  margin: auto;
  margin-top: 15px;
  font-size: 8px;
  line-height: 1.5;
  text-align: center;
  color: #7f7f7f;

  @media (min-width: 768px) {
    margin-top: 6px;
  }

  @media (min-width: 1024px) {
    max-width: 320px;
    margin-bottom: 26px;
  }

  @media (min-width: 1280px) {
    line-height: 1.2;
    margin-top: 7px;
  }

  @media (min-width: 1920px) {
    margin-top: 5px;
    line-height: 1.5;
  }
}

.result__button {
  width: 224px;
  display: block;
  margin: auto;
  margin-top: 27px;

  &--get-exact {
    width: 238px;
    margin-top: 24px;
  }

  @media (min-width: 768px) {
    margin-top: 0;

    &--get-exact {
      display: none;
    }

    &--get-exact-always {
      margin-top: 24px;
      display: block;
    }
  }

  @media (min-width: 1280px) {
    &--get-exact-always {
      margin-top: 15px;
    }
  }

  @media (min-width: 1920px) {
    &--get-exact-always {
      margin-top: 36px;
    }
  }
}

.result__guide-wrapper {
  background-color: rgba(255, 255, 255, 0);
  height: 700px;

  @media (min-width: 1280px) {
    height: 100%;
  }
}

.contact-modal__frame {
  height: 100%;
}

.contact-modal__frame-video {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: none;
}

.result__break {
  @media (min-width: 768px) {
    display: none;
  }
}

.result__guide {
  width: 100%;
  max-width: 530px;
  margin: auto;
  display: grid;
  grid-row-gap: 21px;
  margin-top: 23px;

  @media (min-width: 768px) {
    margin-top: 25px;
    grid-row-gap: 25px;
  }

  @media (min-width: 1024px) {
    max-width: 485px;
    margin-top: 20px;
    grid-row-gap: 24px;
  }

  @media (min-width: 1280px) {
    width: 430px;
    margin-top: 23px;
    grid-row-gap: 27px;
  }

  @media (min-width: 1920px) {
    width: 476px;
    margin-top: 46px;
  }
}

.result__step {
  padding: 0 14px;
  display: grid;
  grid-template-columns: 23px 1fr;
  grid-column-gap: 16px;

  @media (min-width: 768px) {
    grid-template-columns: 33px 1fr;
  }

  @media (min-width: 1024px) {
    padding: 0;
    padding-left: 14px;
  }

  @media (min-width: 1280px) {
    padding-right: 8px;
  }
}

.result__icon-guide-step {
  width: 23px;
  height: 17px;

  @media (min-width: 768px) {
    width: 33px;
    height: 25px;
    margin-top: -3px;
  }

  @media (min-width: 1280px) {
    margin-top: unset;
  }
}

.result__text {
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.35px;

  @media (min-width: 768px) {
    font-size: 16px;
    letter-spacing: -0.4px;
  }

  @media (min-width: 1280px) {
    line-height: 1.5;
    font-size: 18px;
  }

  @media (min-width: 1920px) {
    font-size: 20px;
  }
}

.result__controls-wrapper {
  @media (min-width: 768px) {
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: 50px;
    align-items: center;
    margin: auto;
    margin-top: 40px;
  }

  @media (min-width: 1024px) {
    margin-top: 24px;
    padding-right: 10px;
  }

  @media (min-width: 1280px) {
    margin-top: 50px;
    grid-column-gap: 36px;
  }

  @media (min-width: 1920px) {
    margin-top: 57px;
    grid-column-gap: 50px;
    padding-right: 0;
  }
}

.result__link {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #3f3cca;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 25px;

  @media (min-width: 768px) {
    margin-top: 0;
    padding-left: 10px;
  }
  @media (min-width: 1024px) {
    margin-top: 10px;
    padding-left: 0px;
    padding-bottom: 11px;
  }
}
