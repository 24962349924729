$height: 100px;

.header {
  width: 100%;
  height: $height;
  position: fixed;
  z-index: 1;
  background-color: #fff;
}

.header__wrapper {
  width: calc(100% - 42px);
  height: 100%;
  margin: auto;

  @media (min-width: 1024px) {
    width: calc(100% - 100px);
  }

  @media (min-width: 1428px) {
    width: 1292px;
    padding-left: 36px;
  }
}

.header__logo {
  &--small {
    display: block;
    margin-top: 33px;
  }

  &--big {
    display: none;
  }

  @media (min-width: 1024px) {
    &--small {
      display: none;
    }
  
    &--big {
      display: block;
      margin-top: 25px;
    }
  }
}

.header__mock {
  height: $height;
}

@media (min-width: 1024px) {
  $height: 98px;

  .header {
    height: $height;
  }

  .header__mock {
    height: $height;
  }
}
