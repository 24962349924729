.input {
  width: 100%;
  display: grid;
  grid-row-gap: 7px;
  position: relative;
}

.input__title {
  font-size: 13px;
  padding-left: 6px;

  &--required {
    &::after {
      content: '*';
      color: #fd0100;
    }
  }
}

.input__input {
  width: 100%;
  height: 43px;
  outline: unset;
  border-radius: 6px;
  border: solid 1px #5c57fa;
  background-color: #fff;
  padding: 0 12px;
  font-size: 13px;

  &--error {
    border: solid 1px #fd0100;
    color: #fd0100;
  }
}

.input__error {
  font-size: 10px;
  color: #fd0100;
  position: absolute;
  bottom: -22px;
  left: 6px;
}
